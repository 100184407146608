/* HTML5 */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before,.clearfix:after{content:"";display:table;}
.clearfix:after{clear:both;}
.clearfix{zoom:1;}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

figure {
    margin: 0;
}

/* Base */

body {
    line-height: 1.5;
    margin: 0;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

/* Links */

a {
    color: #06c;
}

a:focus {
    outline: 1px dotted;
}

/* Typography */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1em;
    line-height: 1.25;
    margin: 1em 0 0 0;
    page-break-after: avoid;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.25em;
}

hr {
    border: 0;
    border-top: 1px solid;
    margin: 1em 0;
}

blockquote {
    margin: 1em 2em;
}

p {
    margin: 1em 0;
}

pre {
    margin: 1em 0;
    white-space: pre-wrap;
}

address,
cite,
dfn,
em,
i,
var {
    font-style: italic;
}

b,
strong {
    font-weight: bold;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 0.875em;
}

pre code,
pre kbd,
pre samp {
    font-size: 1em;
}

small {
    font-size: 0.75em;
}

sub,
sup {
    font-size: 0.75em;
    line-height: 0;
}

sub {
    vertical-align: sub;
}

sup {
    vertical-align: super;
}

/* Lists */

dl {
    margin: 1em 0;
}

dd {
    margin-left: 2em;
}

ol,
ul {
    margin: 1em 0;
    padding-left: 2em;
}

ol li {
    list-style: decimal outside none;
}

ol li li {
    list-style: lower-alpha outside none;
}

ol li li li {
    list-style: lower-roman outside none;
}

ul li {
    list-style: disc outside none;
}

ul li li {
    list-style: circle outside none;
}

/* Images */

img {
    border: 0;
    height: auto;
    max-width: 100%;
    display: block;
    padding: 0;
    margin: 0;
}

/* Tables */

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1em auto;
}

td,
th {
    padding: 0.25em 0.5em;
    text-align: left;
}

/* Forms */

button,
input,
select,
textarea {
    border-radius: 0;
    font: inherit;
    margin: 0;
}

button,
input {
    line-height: normal;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

::-ms-clear {
    display: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

input:focus,
textarea:focus {
    outline: 0;
}

:invalid {
    box-shadow: none;
}