.gallery{
	position: relative;
	padding: 0 0 9px 0;
    
	@include media(medium){
		margin: 0 25px;
	}
	@include media(large){
		margin: 0;
	}
	@include media(extra-large){
		margin: 0 20px;
	}
	
    a{  
        max-width: 100%;
		display: relative;
		
        img{
            display: block;
            max-width: 100%;
            border: solid 5px $slate-grey-two;
            
            @include media(small-medium){
                border: solid 10px $slate-grey-two;
            }
            @include media(extra-large){
                border: solid 20px $slate-grey-two;
            }
        }
		
		&.enableLightbox{
			&:after{
				display: block;
				background: $sprite no-repeat 10px -1928px $half-black; 
				width: 50px; 
				height: 50px;
				padding: 10px;
				position: absolute;
				right: 30px;
				bottom: 30px;
				content: "";
				}
		}
    }
    
    @include media(small-medium){
        a:after{
            display: block;
            background-image: $sprite;
            background-position: 10px -1928px;
            width: 30px; 
            height: 30px;
            padding: 25px;
            position: absolute;
            bottom: 30px;
            right: 30px;
            content: "";
            z-index: 100;
            background-color: rgba(0,0,0,0.5);
        }
    }
    
}

.slideshow-navigation{
    position: relative;
    height: 55px;
    margin: 9px 0 25px 0;
    
    
    @include media(small-medium){
        margin: 9px 0 60px 0;
        height: auto;
    }
    
    @include media(medium){
		margin: 9px 25px 60px 25px;
        max-width: 700px;
	}
	@include media(large){
		margin: 9px 0 60px 0;
        
	}
	@include media(extra-large){
		margin: 9px 20px 60px 20px;
        max-width: 750px;
	}
    
    .cycle-slide{
        padding: 0 3px;
        
        img{
            border: 3px solid $ocean-blue;
            display: block;
            boz-sizing: border-box;
        }
    }
}

#cycle-2{
    display: none;
    
    @include media(small-medium){
        display: block;
    }

}

.slide-nav{
    display: block;

    @include media(small-medium){
        top: 0;
        width: 45px;
    }
    
    @include media(medium){
        width: 65px;
    }
    
    opacity: 0.7;
    background-color: #000000;
    width: 65px;
    height: 100%;
    position: absolute;
    display: block;
    
    &:hover{	
        opacity: 0.9;
    }
    
    &:after{
        display: block;
        background-image: $sprite;
        width: 15px; 
        padding: 25px;
        position: absolute;
        top: calc(50% - 13px);
        left: 25px;
        content: "";
        
        @include media(small-medium){
            left: 14px;
        }
        
        @include media(medium){
            left: 25px;
        }
    }
    
    &.cycle-prev{
        left: 0;
        &:after{
            background-position: 0 -594px; 
        }
    }
    
    &.cycle-next{
        right: 0;
        &:after{
            background-position: 0 -2898px; 
        }
    }
}