.breadcrumbs{
    background-color: $white-three;
    padding: 20px 5.6%;
    
    p{
        color: $ocean-blue;
        font-weight: bold;
        margin: 0;
        line-height: 1em;
        text-transform: uppercase;
        font-size: 0.727em;
        
        a{
            color: $charcoal-grey-four;
            font-weight: normal;
            text-decoration: none;
        }
    }
}