.footer-container{
    width: 100%;
    
    .footer-logos{
        background-color: $white-three;
        padding: 6%;
        
        img{
            width: 46.665%;
            max-height: 105px;
            border-radius: 5px;
            border: solid 1px $white-two;
            float: left;
            padding: 10px;
            
            @include media(small-medium-2){
                width: 41%;
            }

            @include media(medium) {
                width: calc(20% - 2px);
                padding: 20px;
                margin-top: 0;
                margin-left: 0;
            }
        }
        
        img:nth-of-type(2n) {
            margin-left: 6.67%;
        }  
        
        img:nth-child(3), img:nth-child(4) {
            margin-top: 6.67%;
        }   
        
        @include media(small-medium-2){
            img:nth-of-type(2n) {
                margin-left: 18%;
            }  
        }
        
        @include media(medium) {
            img:nth-child(2), img:nth-child(3), img:nth-child(4) {
                margin-left: 6.67%;
            }      
            
            img:nth-child(3), img:nth-child(4) {
                margin-top: 0;
            }              
        }
    }
    
    .footer{
        background-color: $charcoal-grey-two;
        width: 100%;
        padding: 5.6%;
        
        @include media(small-medium){
            padding-bottom: 40px;
        }
        
        h4{
            color: $turtle-green;
            font-weight: 500;
            font-size: 1em;
            margin: 0 0 16px 0;
            
            @include media(small-medium){
                margin: 0 0 36px 0;
            }
        }
        
        p, a{
            margin: 0;
            font-size: 0.727em;
            
            @include media(small-medium){
                font-size: 1em;
            }
        }   
        
        .footer-services{
            
            a{
                list-style-type: none;
                padding: 20px 20px;
                border: 1px solid $slate-grey;
                border-radius: 3px;
                margin: 0 0 19px 0;
                display: block; 
                font-size: 16px;
                line-height: 16px;
                color: $white;
                text-decoration: none;
                width: 100%;
                position: relative;
                
                &:hover{
                    background-color: $charcoal-grey-four;
                }
                
                &:before{
                    height: 16px;
                    width: 16px;
                    margin: 0 20px 0 0;
                    content: "";
                    display: inline-block;
                    background: $sprite no-repeat 0 0;
                }
                
                &:after{
                    height: 16px;
                    width: 16px;
                    margin: 0 20px 0 0;
                    content: "";
                    display: inline-block;
                    background: $sprite no-repeat 0 0;
                    @include icons($footer-arrow);
                    position: absolute;
                    right: 0;
                }
            }
            
            & .school:before{
                @include icons($school);
            }
            & .nhs:before{
                @include icons($nhs);
            }
            & .building:before{
                @include icons($building);
            }
            & .offices:before{
                @include icons($offices);
            }
            & .local:before{
                @include icons($localAuth);
            }
            & .retail:before{
                @include icons($retail);
            }
            & .leisure:before{
                @include icons($lesiure);
            }
            
            @media screen and (min-width: 630px) {
                a{
                    width: 47%;
                    float: left;
                    margin-right: 6%;
                    
                    &:nth-of-type(2n){
                        margin-right: 0;
                    }
     
                }
            }
            
            @include media(medium){
                width: 100%;
            }
            
            @include media(large-2){
                width: 35%;
                float: left;
                margin-right: 5%;
                
                a{
                    width: 100%;
                    margin-right: 0;
                    
                    &:nth-of-type(2n){
                        margin-right: 0;
                    }
     
                }
            }
            
            @include media(extra-large){
                width: 43%;
                
                a{
                    width: 47%;
                    margin-right: 6%;
                }
            }
        }
        
        .footer-types{
            a{
                background-color: $charcoal-grey-three;
                padding: 15px 10px 13px 10px;
                font-size: 0.727em;
                color: $white;
                font-weight: bold;
                text-decoration: none;
                width: 48.5%;
                float: left;
                display: block;
                margin-bottom: 1.5%;
                text-align: center;
                line-height: 1em;
                
                @include media(small-medium){
                    padding: 20px 20px 17px 20px;
                }

                @include media(medium){
                    margin-bottom: 7%;
                    width: auto;
                    max-width: 46%;
                    text-align: left;
                }
                
                @media screen and (min-width: 800px) {
                    &:nth-of-type(4), &:nth-of-type(6){
                        margin-left: 0 !important;
                    }
                }
                
                &:hover{
                    background-color: $charcoal-grey-four;
                }
            }   
            
            a:nth-of-type(2n){
                margin-left: 1.5%;
                
                @include media(medium){
                    margin-left: 7%;
                    margin-right: 10%;
                }
            }
            
            @include media(medium){
                width: 48%;
                margin-right: 4%;
                float: left;
            }
            
            @include media(large-2){
                width: 25%;
                margin-right: 3%;
                
                a{
                    width: 100%;
                    max-width: 100%;
                    
                    &:nth-of-type(2n){
                        margin-left: 0%;
                    }
                }
            }
            @include media(extra-large){
                width: 23%;
                
                a{
                    width: 46.5%;
                    
                    &:nth-of-type(2n){
                        margin-left: 7%;
                        margin-right: 0;
                    }
                    
                    &:nth-of-type(4), &:nth-of-type(6){
                        margin-left: 7% !important;
                    }
                }
            }
            
            @media screen and (min-width: 1400px) {
                a{
                    width: auto;
                    max-width: 46.5%;
                }
            }
        }
        
        .footer-details{
            
            p{
                padding: 20px 10px 20px 40px;
                color: $white;
                border: 1px solid $slate-grey;
                border-radius: 3px;
                margin: 20px 0;
                
                &:before{
                        height: 14px;
                        width: 13px;
                        margin: 0 13px 0 -25px;
                        content: "";
                        display: inline-block;
                        background: $sprite no-repeat 0 0;
                }
                
                &.address:before{
                    @include icons($footer-map);
                }
                
                &.tel:before{
                    @include icons($footer-tel);
                }
                
                &.email:before{
                    @include icons($footer-email);
                }
                
                &.address{
                    font-size: 0.636em;
                }     

                &.email{
                    font-size: 0.545em;
                    margin-bottom: 0;
                    line-height: 1em;
                }          
                
                &.tel{
                    font-size: 0.727em;
                    line-height: 1em;
                }

                @include media(small-medium){
                    padding: 20px 20px 20px 53px;
                    
                    &:before{
                        margin: 0 20px 0 -33px;
                    }
                    
                    &.email{
                        font-size: 0.727em;
                    }          
                    
                    &.tel{
                        font-size: 0.727em
                    }
                }                
            }
            
            @include media(medium){
                width: 48%;
                float: left;
            }
            
            @include media(large-2){
                width: 32%;
            }
            
            @include media(extra-large){
                width: 26%;
            }
        }
        .footer-services, .footer-types{
            @include media(small-medium){
                padding-bottom: 20px;
            }
        }
    }
    .footer-copyright{
        background-color: $charcoal-grey-three;
        color: $white;
        font-size: 0.545em;
        width: 100%;
        padding: 5.6%;
        text-align: center;
   
        @include media(small-medium){
            padding: 20px 5.6% 25px 5.6%;
        }
        
        p{
            margin: 0;
            padding: 0;
            line-height: 1.5em;
        }
        
        a{
            color: $white;
            text-decoration: none;
            
            &:hover{
                color: $turtle-green;
            }
        }
        
        @include media(medium){
            padding: 40px 5.6% 45px 5.6%;
            
            .copyright{
                text-align: left;
                float: left;
                width: 48%;
            }
            
            .company{
                text-align: right;
                float: right;
                width: 50%;
                margin-left: 2%;
            }
        }

    }
}