.triangle-container{
    h2{
        margin-top: 0;
    }

    .text-area{
        padding: 25px 5.7% 10px 5.7%;
        background-color: $white-three;

        @include media(medium){

        }

        @include media(large){
            padding: 40px 5.7% 18px 5.7%;
        }
    }

}

.tri-image{
    position: relative;

    &.tri-left, &.tri-right{
        width: 100%;
        display: block;
        max-width: 100%;
        overflow: hidden;

        @include media(medium) {
            height: 100%;
        }


        &:before, &:after{
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            line-height: 0px;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            -webkit-transform:rotate(360deg);
        }
    }

    &.tri-left{
        &:before{
            bottom: 0;
            left: 0;
            z-index: 1;
            border-width: 50px 0 0 240px;
            border-color: transparent transparent transparent $white-three;
            _border-color: #000000 #000000 #000000 $white-three;
        }

        &:after{
            bottom: 0;
            right: 0;
            z-index: 3;
            border-width: 0 0 50px 240px;
            border-color: transparent transparent $white-three transparent;
            _border-color: #000000 #000000 $white-three #000000;
        }

        @include media(small-medium) {
            &:before{
                border-width: 50px 0 0 280px;
            }

            &:after{
                border-width: 0 0 50px 280px;
            }
        }

        @include media(small-medium-2) {
            &:before{
                border-width: 75px 0 0 305px;
            }

            &:after{
                border-width: 0 0 75px 305px;
            }
        }

        @include media(small-medium-3) {
            &:before{
                border-width: 50px 0 0 384px;
            }

            &:after{
                border-width: 0 0 50px 384px;
            }
        }

        @include media(medium) {
            &:before{
                top: 0;
                z-index: 3;
                border-width: 0px 850px 114px 0;
                border-color: transparent #ffffff transparent transparent;
                _border-color: #000000 #ffffff #000000 #000000;
            }

            &:after{
                bottom: 0;
                z-index: 1;
                border-width: 0 0 271px 850px;
                border-color: transparent transparent $white-three transparent;
                _border-color: #000000 #000000 $white-three #000000;
            }
        }

        @media screen and (min-width: 850px) {
            &:before{
                border-width: 0px 910px 122px 0;
            }

            &:after{
                border-width: 0 0 291px 910px;
            }
        }

        @include media(medium-2) {
            &:before{
                border-width: 0px 1024px 138px 0;
            }

            &:after{
                border-width: 0 0 327px 1024px;
            }
        }
        @include media(large) {
            &:before{
                border-width: 0px 1150px 155px 0;
            }

            &:after{
                border-width: 0 0 376px 1150px;
            }
        }
        @media screen and (min-width: 1150px) {
            &:before{
                border-width: 0px 1250px 122px 0;
            }

            &:after{
                border-width: 0 0 399px 1250px;
            }
        }
        @media screen and (min-width: 1250px) {
            &:before{
                border-width: 0px 1395px 188px 0;
            }

            &:after{
                border-width: 0 0 445px 1395px;
            }
        }
    }

    &.tri-right{
            &:before{
                top: 0;
                left: 0;
                z-index: 1;
                border-width: 50px 240px 0 0;
                border-color: $white-three transparent transparent transparent;
                _border-color: $white-three #000000 #000000 #000000 ;
            }

            &:after{
                top: 0;
                right: 0;
                z-index: 3;
                border-width: 0 240px 50px 0; //240
                border-color: transparent $white-three transparent  transparent;
                _border-color: #000000 $white-three #000000 #000000;
            }

        @include media(small-medium) {
            &:before{
                border-width: 50px 280px 0 0;
            }

            &:after{
                border-width: 0 280px 50px 0;
            }
        }

        @include media(small-medium-2) {
            &:before{
                border-width: 75px 305px 0 0;
            }

            &:after{
                border-width: 0 305px 75px 0;
            }
        }

        @include media(small-medium-3) {
            &:before{
                border-width: 50px 384px 0 0;
            }

            &:after{
                border-width: 0 384px 50px 0;
            }
        }


        @include media(medium) {
            &:before{
                top: 0;
                border-width: 193px 768px 0 0;
                border-color: $white-three transparent transparent transparent;
                _border-color: $white-three #000000 #000000 #000000;
            }

            &:after{
                bottom: 0;
                top: unset;
                left: 0;
                border-width: 114px 0 0 768px;
                border-color: transparent transparent transparent #ffffff;
                _border-color: #000000 #000000 #000000 #ffffff;
            }
        }

        @media screen and (min-width: 850px) {
            &:before{
                border-width: 228px 910px 0 0;
            }

            &:after{
                border-width: 122px 0 0 910px;
            }
        }

        @include media(medium-2) {
            &:before{
                border-width: 257px 1024px 0 0;
            }

            &:after{
                border-width: 138px 0 0 1024px;
            }
        }
        @include media(large) {
            &:before{
                border-width: 289px 1150px 0 0;
            }

            &:after{
                border-width: 155px 0 0 1150px;
            }
        }
        @media screen and (min-width: 1150px) {
            &:before{
                border-width: 301px 1250px 0 0;
            }

            &:after{
                border-width: 168px 0 0 1250px;
            }
        }
        @media screen and (min-width: 1250px) {
            &:before{
                border-width: 350px 1395px 0 0;
            }

            &:after{
                border-width: 187px 0 0 1395px;
            }
        }
    }
}