.ftype-box{
    padding: 0;
    margin: 10px auto 5px auto;
    width: 100%;
    position: relative;
    text-decoration: none;
    display: table;
    max-width: 350px;
    max-height: 350px;
    
    &:hover{
       filter: brightness(110%);
    }

    @include media(small-medium){
        width: calc(50% - 5px);      
        float: left; 
        margin: 0 0 1.5% 0;
        
        &:nth-of-type(2n){
            margin-left: 10px;
        }
    }
    
    @include media(medium){
        width: 32.3%;      
        margin: 0;
        
        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3){
            margin-bottom: 10px;  
        }
        &:nth-of-type(2n){
            margin-left: 0px;
        }
        
        &:nth-of-type(2), &:nth-of-type(3n), &:nth-of-type(5){
            margin-left: 1.5%;
        }
    }
    
    @include media(large-2){
        width: 30%; 
            
            
        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3){
            margin-bottom: 5%;  
        }
        &:nth-of-type(2), &:nth-of-type(3n), &:nth-of-type(5){
            margin-left: 5%;
        }
    }
    
    @include media(extra-large){
        width: 28%; 
        
        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3){
            margin-bottom: 80px;  
        }    
        &:nth-of-type(2), &:nth-of-type(3n), &:nth-of-type(5){
            margin-left: 8%;
        }
    }
    
    img{
        position: relative;
        
    }
    
    .text{
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: calc(50% + 38px);
        z-index: 2;
    }

    p{
        font-size: 1.045em;
        font-weight: bold;
        text-align: center;
        display: block;
        margin: 0 auto;
        line-height: 1em;
        vertical-align: middle;
        border: solid 5px #ffffff;
        padding: 15px;
        max-width: 75%;
        color: $white;
        
        @include media(small-medium){
            font-size: 1em;
        }   
        
        @include media(medium){
            font-size: 1em;
            padding: 15px;
        }
        
        @include media(large){
            font-size: 1.2em;
            padding: 20px;
        }
        
        @include media(extra-large){
            font-size: 1.382em;
        }

    }
}