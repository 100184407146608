.homepage-cta-services{
    width: 100%;
    margin: 0 auto;
}

.all-services{
    a{
        width: 100%;
        margin: 0;
        padding: 30px 0;
        display: block;
        background-color: $white-three;
        text-align: center;
        text-transform: uppercase;
        color: $medium-purple;
        font-weight: bold;
        text-decoration: none;

        &:hover{
            color: $white-three;
            background-color: lighten($medium-purple, 20%);
        }
    }
}

.home-content{
    padding: 5.7%;

    h2{
        color: $medium-purple;
        margin-top: 0;
    }

    p{
        margin: 1.182em 0;
    }

    img{
        border: solid 8px $white-four;

        @include media(medium) {
            width: 45%;
            margin: 1.182em 0 1.182em 7%;
            float: right;
        }
    }
}

.home-flooring-types{
    background-color: $white-three;

    h2{
        margin-top: 0;
    }

    .flooring-types-container{
        padding: 20px;

        @include media(medium) {
            padding: 45px 77px;
        }

        @import 'homepage-ftype-box';

    }

}

.get-a-quote-container{

    @include media(medium){
        padding: 5.7%;
    }

    @include media(large){
        padding: 5.7% 5.7% 50px 5.7%;
    }

    .contact-text{

        @include media(medium){
            width: 48.5%;
            float: left;
            margin-right: 3%;

            h3{
                margin-top: 0;
            }
        }

        @include media(large){
            width: 46%;
            margin-right: 4%;
        }

        @include media(extra-large){
            margin-right: 8%;
            width: 46%;
        }
    }
    .homepage-contact-form{

        @include media(medium){
            width: 48.5%;
            float: right;
        }

        @include media(large){
            width: 50%;
        }

        @include media(extra-large){
            width: 46%;
        }
    }
}