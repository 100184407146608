@mixin media($size) {
    $env: main !default;
    @if $env == ie {
        @content;
    }
    @else if $size == small {
        @media screen and (min-width: 320px) { @content; }
    }
    @else if $size == small-medium {
        @media screen and (min-width: 480px) { @content; }
    }
    @else if $size == small-medium-2 {
        @media screen and (min-width: 560px) { @content; }
    }
    @else if $size == small-medium-3 {
        @media screen and (min-width: 610px) { @content; }
    }
    @else if $size == medium {
        @media screen and (min-width: 768px) { @content; }
    }
    @else if $size == medium-2 {
        @media screen and (min-width: 910px) { @content; }
    }
    @else if $size == large {
        @media screen and (min-width: 1024px) { @content; } 
    }
    @else if $size == large-2 {
        @media screen and (min-width: 1140px) { @content; } 
    }
    @else if $size == extra-large {
        @media screen and (min-width: 1395px) { @content; }
    }
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


// Vendor Prefixes
@mixin prefix($property, $values...) {
    -webkit-#{$property}: $values;
    -moz-#{$property}: $values;
    -ms-#{$property}: $values;
    -o-#{$property}: $values;
    #{$property}: $values;
}

// Screen size indicator
.size {
    display: block;
    position: fixed;
    top:0;
    left: 10px;
    height: 20px;
    width: 20px;
    background-color: red;
    z-index: 200;

    @include media(small){
        background-color: orange;
    }
    @include media(small-medium){
        background-color: yellow;
    }
    @include media(small-medium-2){
        border: 2px solid black;
    }
    @include media(small-medium-3){
        border: 2px solid red;
    }
    @include media(medium){
        background-color: #97C81E /* green */;
        border: none;
    }
    @include media(large){
        background-color: blue;
    }
    @include media(large-2){
        border: 2px solid black;
    }
    @include media(extra-large){
        background-color: black;
        border: none;
    }
}