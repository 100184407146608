// Colours
$ocean-blue: #0065b7;
$squash: #e88c14;
$teal-blue: #00a1b0;
$turtle-green: #79bc50;
$medium-purple: #884ba1;
$charcoal-grey: #363d48;
$moss-green: #5d9739;
$white: #ffffff;
$white-two: #dbdbdb;
$charcoal-grey-two: #2c303b;
$slate-grey: #505666;
$charcoal-grey-three: #353845;
$black: #1e1d1d;
$royal: #131176;
$charcoal-grey-four: #414654;
$white-three: #f7f7f7;
$white-four: #ebebeb;
$line-grey: #aaaaaa;
$slate-grey-two: #dfdfdf;
$slate-grey-three: #d9d9d9;
$half-black: rgba(0,0,0,0.5);

.moss-green{
    color: $moss-green;
}
