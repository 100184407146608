.service-page, .sector-page{
    .text-container{
        .light{
            img{
                border: solid 8px $white-four;
                max-width: 100%;
            }
        }
        
        @include media(large){
            width: 60%;
            float: left;
        }
    }
    
}
   
.aside{
    margin: 5.7%;
    
    @include media(large){
        width: 32.3%;
        float: left;
        margin: 5.7% 5.7% 5.7% 2%;
        padding: 20px;
    }
    @include media(extra-large){

        width: 30.3%;
        padding: 30px;
        margin: 5.7% 5.7% 5.7% 4%;
    }
	
	.side-nav{
		border-left: 1px solid $slate-grey-three;
		padding-left: 12%;
		margin-bottom: 5.6%;
		
		@include media(large){
			margin-bottom: 80px;
		}
		
		h4{
			color: $ocean-blue;
			font-size: 1.382em;
			padding-left: 22px;
		}
		
		ul{
			margin: 0;
			padding: 0;
			
			li{
				list-style-type: none;
				border-left: 5px solid $white;
				padding: 0 0 0 17px;
				margin: 25px 0;
				line-height: 1em;
				
				a{
					text-decoration: none;
					color: $charcoal-grey;
				}
				
				&.active{
					font-weight: bold;
					border-left: 5px solid $ocean-blue;
				}
			}
		}
	}
	
	.get-a-quote-box{
		background-color: $ocean-blue;
		color: $white;
		padding: 6%;
		
		@include media(small-medium){
			padding: 10%; 
		}
		@include media(large){
			padding: 17.8%; 
		}
		
		h4{
			font-size: 1.382em;
			color: $white;
			margin: 0 0 24px 0;
		}
		
		p{
			font-size: 0.727em;
		}
		
		a{
            background-color: $ocean-blue;
            -webkit-transition: .3s ease;
            -moz-transition: .3s ease;
            -o-transition: .3s ease;
            -ms-transition: .3s ease;
            transition: .5s ease;
            border: solid 5px $white;
            color: $white;
            font-weight: 600;
            padding: 20px 15px;
            line-height: 1em;
			display: block;
			margin: 40px auto 0;
			text-decoration: none;
			text-align: center;
            
            &:hover{
                background-color: $white;
                color: $ocean-blue;
            }            
		}
	}
}