// Fonts

$lato: 'Lato', sans-serif;

body{
    font-family: $lato;
    font-size: 1.375em;
    font-weight: normal;
    line-height: 1.43em;
    text-align: left;
    color: $charcoal-grey;
}

h2{
    color: $medium-purple;
    font-size: 2.036em;
    font-weight: bold;
}

h3{
    font-size: 1.364em;
    font-weight: bold;
    color: $medium-purple;
}

// In content -> main -> text-container.The text container hold the main body of text on a page.
.text-container{
     padding: 5.7%;

    h2:first-of-type{
        margin-top:0;
    }
}

/*.tiled-page .text-container, .service-page .text-container, .sector-page .text-container{
    h2{
        color: $charcoal-grey-four;
    }
}*/

.contact-text h3{
    font-family: $lato;
    font-size: 2.036em;
    font-weight: bold;
    color: $ocean-blue;
}

.hcentral{
    text-align: center;
}

//Sprite styles
.sprite { background: url('#{$url}/static/images/sprite.png') no-repeat top left; width: 30px; height: 26px;  }

$sprite: url('#{$url}/static/images/sprite.png');

$school: 0 -1018px;
$nhs: 0 -106px;
$building: 0 -1612px;
$offices: 0 -266px;
$localAuth: 0 -2554px;
$retail: 0 -840px;
$lesiure: 0 -2390px;
$footer-arrow: 0 -2206px;
$footer-map: 0 -2718px;
$footer-tel: 0 -426px;
$footer-email: 0 -1779px;

@mixin icons($position) {
  background-position: $position;
}

.sprite.next-arrow3x { background-position: 0 0; width: 45px; height: 78px; }
.sprite.nhs-icon { background-position: 0 -108px; width: 12px; height: 12px; }
.sprite.nhs-icon2x { background-position: 0 -150px; width: 24px; height: 24px; }
.sprite.nhs-icon3x { background-position: 0 -204px; width: 36px; height: 36px; }
.sprite.offices-icon { background-position: 0 -270px; width: 8px; height: 11px; }
.sprite.offices-icon2x { background-position: 0 -311px; width: 16px; height: 22px; }
.sprite.offices-icon3x { background-position: 0 -363px; width: 24px; height: 33px; }
.sprite.phone-icon { background-position: 0 -426px; width: 13px; height: 13px; }
.sprite.phone-icon2x { background-position: 0 -469px; width: 26px; }
.sprite.phone-icon3x { background-position: 0 -525px; width: 39px; height: 39px; }
.sprite.prev-arrow { background-position: 0 -594px; width: 15px; }
.sprite.prev-arrow2x { background-position: 0 -650px; height: 52px; }
.sprite.prev-arrow3x { background-position: 0 -732px; width: 45px; height: 78px; }
.sprite.retail-icon { background-position: 0 -840px; width: 13px; height: 15px; }
.sprite.retail-icon2x { background-position: 0 -885px; width: 26px; height: 30px; }
.sprite.retail-icon3x { background-position: 0 -945px; width: 39px; height: 45px; }
.sprite.schools { background-position: 0 -1020px; width: 15px; height: 15px; }
.sprite.schools2x { background-position: 0 -1065px; height: 30px; }
.sprite.schools3x { background-position: 0 -1125px; width: 45px; height: 45px; }
.sprite.send-arrow { background-position: 0 -1200px; width: 10px; height: 16px; }
.sprite.send-arrow2x { background-position: 0 -1246px; width: 20px; height: 32px; }
.sprite.send-arrow3x { background-position: 0 -1308px; height: 48px; }
.sprite.blue-arrow { background-position: 0 -1386px; width: 15px; height: 23px; }
.sprite.blue-arrow2x { background-position: 0 -1439px; height: 46px; }
.sprite.blue-arrow3x { background-position: 0 -1515px; width: 45px; height: 69px; }
.sprite.contractors-icon { background-position: 0 -1614px; width: 14px; height: 13px; }
.sprite.contractors-icon2x { background-position: 0 -1657px; width: 28px; }
.sprite.contractors-icon3x { background-position: 0 -1713px; width: 42px; height: 39px; }
.sprite.email-icon { background-position: 0 -1782px; width: 13px; height: 11px; }
.sprite.email-icon2x { background-position: 0 -1823px; width: 26px; height: 22px; }
.sprite.email-icon3x { background-position: 0 -1875px; width: 39px; height: 33px; }
.sprite.enlarge-icon { background-position: 0 -1938px; height: 30px; }
.sprite.enlarge-icon2x { background-position: 0 -1998px; width: 60px; height: 60px; }
.sprite.enlarge-icon3x { background-position: 0 -2088px; width: 90px; height: 90px; }
.sprite.footer-arrow { background-position: 0 -2208px; width: 10px; height: 16px; }
.sprite.footer-arrow2x { background-position: 0 -2254px; width: 20px; height: 32px; }
.sprite.footer-arrow3x { background-position: 0 -2316px; height: 48px; }
.sprite.leisure-icon { background-position: 0 -2394px; width: 9px; height: 12px; }
.sprite.leisure-icon2x { background-position: 0 -2436px; width: 18px; height: 24px; }
.sprite.leisure-icon3x { background-position: 0 -2490px; width: 27px; height: 36px; }
.sprite.local-icon { background-position: 0 -2556px; width: 13px; height: 12px; }
.sprite.local-icon2x { background-position: 0 -2598px; width: 26px; height: 24px; }
.sprite.local-icon3x { background-position: 0 -2652px; width: 39px; height: 36px; }
.sprite.map-marker { background-position: 0 -2718px; width: 10px; height: 15px; }
.sprite.map-marker2x { background-position: 0 -2763px; width: 20px; height: 30px; }
.sprite.map-marker3x { background-position: 0 -2823px; height: 45px; }
.sprite.next-arrow { background-position: 0 -2898px; width: 15px; }
.sprite.navigation-icons { background-position: 0 -2954px; width: 60px; height: 60px; }
.sprite.next-arrow2x { background-position: 0 -3044px; height: 52px; }