.contact-page{

    .text-container{

        @include media(large){
            width: 60%;
            float: left;
        }
    }

    .contact-details{
        background-color: $ocean-blue;
        color: $white;
        padding: 6%;
        margin: 5.7%;

        @include media(large){
            width: 32.3%;
            float: left;
            margin: 5.7% 5.7% 5.7% 2%;
            padding: 20px;
            font-size: 0.818em;
        }
        @include media(extra-large){
            font-size: 1em;
            width: 30.3%;
            padding: 30px;
            margin: 5.7% 5.7% 5.7% 4%;
        }

        p:first-of-type{
            margin-top: 0;
        }

        p:last-of-type{
            margin-bottom: 0;
        }

        p{
            font-size: 0.636em;

            @include media(small){
                font-size: 0.727em;
            }
            @include media(small-medium){
                font-size: 1em;
            }
        }

        a{
            color:white;
            text-decoration:none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .google-map{
        background-color: $ocean-blue;
        color: $white;
        padding: 6%;
        margin: 0 5.7% 5.7% 5.7%;

        @include media(large){
            width: 32.3%;
            float: left;
            margin: 0 5.7% 5.7% 2%;
            padding: 20px;
        }
        @include media(extra-large){
            width: 30.3%;
            padding: 10px;
            margin: 0 5.7% 5.7% 4%;
        }

        #map {
            height: 400px;
            width: 100%;
        }

        .map-contact-info-box{
            h4{
                color: $ocean-blue;
                margin: 0 0 0.5em;
                font-size: 1.5em;
            }
            p{
                margin: 0 0 0.5em;
                color: $charcoal-grey;
            }
        }
    }
}