form{
    background-color: $ocean-blue;
    padding: 6%;
    
    @include media(medium) {
        background-color: $white;
        padding: 0;
    }
    
    .field{
        width: 100%;
        margin: 15px 0 0 0;
        
        &:first-of-type{
            margin-top: 0;
        }   
        
        @include media(small-medium) {
            margin: 30px 0 0 0;
        }
        
        @include media(large){
            &:first-of-type{
                width: 45%;
                float: left;
            } 
            &:nth-of-type(2){
                width: 45%;
                float: left;
                margin-left: 10%;
                margin-top: 0;
            } 
            &:nth-of-type(3),&:nth-of-type(4),&:nth-of-type(5){
                float: left;
            }
        }
        
        .text-label{
            color: $white;
            font-size: 0.818em;
            font-weight: 700;
            display: block;
            
            @include media(medium) {
                color: $ocean-blue;
            }
            
        }
        
        input{
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid $white-four;
            display: block;
            color: $white-four;
            width: 100%;
            font-size: 0.727em;
            background-color: $ocean-blue;
            
            &:focus &:hover{
                border-bottom: 1px solid $ocean-blue;
            }
            
            @include media(medium) {
                color: $charcoal-grey;
                background-color: $white;
                border-color: $line-grey;
            }
        }
        
        textarea{
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid $white-four;
            max-width: 100%;
            max-height: 100px;
            height: 100px;
            color: $white-four;
            width: 100%;
            font-size: 0.727em;
            background-color: $ocean-blue;
            
            &:focus &:hover{
            
            }
            
            @include media(medium) {
                color: $charcoal-grey;
                background-color: $white;
                border-color: $line-grey;
            }
        }
    }
    
    .submit{
        .button{
            background-color: $ocean-blue;
            -webkit-transition: .3s ease;
            -moz-transition: .3s ease;
            -o-transition: .3s ease;
            -ms-transition: .3s ease;
            transition: .5s ease;
            border: solid 5px $white;
            color: $white;
            font-size: 1.364em;
            font-weight: 600;
            padding: 10px 15px;
            line-height: 1em;
            float: right;
            
            &:after{
                content: "";
                background: url('#{$url}/static/images/send-arrow.png') no-repeat right center transparent;
                width: 10px;
                height: 24px;
                display: inline-block;
                margin-left: 20px;
            }
            
            &:hover{
                background-color: $white;
                color: $ocean-blue;
                
                &:after{
                    background-position: 0 center;
                }
            }
            
            @include media(medium) {
                background-color: $white;
                border: solid 5px $ocean-blue;
                color: $ocean-blue;
                
                &:after{
                    background-position: 0 center;
                }
                
                &:hover{
                    background-color: $ocean-blue;
                    color: $white;
                    &:after{
                        background-position: right center;
                    }
                }
                
            }
        }
    }
}

