.banner{
    position:relative;
    max-height: 475px;
    margin-bottom: 1em;

    .cycle-overlay{
        background-color: $teal-blue;
        background-color: rgba(0,161,176,0.55);
        font-size: 1.045em;
        text-align: left;
        color: #ffffff;
        text-shadow: 0 0 14px #005962;
        line-height: 1.2em;
        font-weight: bold;

        @include media(medium) {
            height: 66%;
            width: 50%;
            padding: 40px 40px;
            z-index: 2000;
            position: absolute;
            margin: 7% 50% 0 0;
            top: 0;
            overflow: hidden;
            font-size: 1.045em;
        }

        @include media(large) {
            font-size: 1.455em;
            padding: 40px 40px;
            margin: 70px 50% 0 0;
        }

        @include media(extra-large) {
            font-size: 2.036em;
            margin: 80px 50% 0 0;
            padding: 78px 40px;
        }
    }
.cycle-overlay p{
    margin: 0;
    text-align: center;
    @include media(small-medium-3){
        margin: 1em 0;
        text-align: center;
    }
    @include media(large){
        margin: 1.5em 0;
    }
    @include media(large-2){
        margin: 1.5em 0;
    }
    @include media(extra-large){
        margin: 0;
    }
}
}

.banner-about{
    img{
    max-height: 475px;
    margin: 0 auto;
}
}
