// Header

.test{
    background-color: $moss-green;
    display: flex;
    height: 300px;
    width: 100%;
    justify-content: center;
    align-items: center;

    .centered{
        background-color: $medium-purple;
        flex-basis: 50%;

        p{
            background-color: $squash;
        }
    }
}

.header{
    width: 100%;
  //  background-color: pink;
    box-sizing: border-box;

    @include media(medium) {
        padding: 30px;
    }

    @include media(large) {
        padding: 40px;
    }

    .company-details{

        @include media(medium) {
            width: 45%;
            float: left;
        }

        @include media(large) {
            width: 30%;
        }


        h1{
            margin: 0;

            img{
                max-width: 386px;
                width: 100%;

                @include media(small) {
                    margin: 0 auto;
                }
            }


        }

        .slogan{
            font-size: 0.727em;
            font-weight: 700;
            line-height: 1em;
            text-align: left;
            color: $black;
            margin: 17px 0 0 0;
            padding: 0;
            text-align: center;

            @include media(small) {
               text-align: left;
            }

            @include media(small-medium-3) {
               float: left;
               width: 30%;
            }

            @include media(medium) {
                width: 60%;
            }
        }
    }

    .contact-details{
        font-size: 0.636em;

        @include media(small-medium-3) {
            font-size: 0.727em;
            width: 60%;
            margin-left: 10%;
            float: right;
            margin-top: 17px;
        }

        @include media(medium) {
            width: 50%;
            display:inline-block;
            margin-left: 5%;
            margin-top: 0;
        }

        @include media(large) {
            width: 50%;
            margin-left: 20%;
        }

        p{
            float: left;
            width: 100%;
            margin: 0;
            padding: 5px 0 0 0;
            line-height: 1.5em;
            text-align: center;

            @include media(small) {
                width: 69%;
                text-align: left;
            }

            @include media(small-medium-3) {
                text-align: right;
                line-height: 1em;
                padding: 17px 0 0 0;
                width: 66%;
                vertical-align: text-top;

                &:first-of-type{
                   padding-top: 0;
                }
            }

            @include media(medium) {
                width: 79%;
            }
        }

        img{
            margin: 0 auto;


            @include media(small) {
                max-width: 26%;
                margin: -35px 0 0 5%;
                float: right;
            }

            @include media(small-medium-3) {
                margin-top: -16px;
            }

            @include media(medium) {
                max-width: 16%;

            }
        }

    }

    .nav{

        @include media(small-medium-3) {
            width: 100%;
            float: right;
        }

        @include media(medium-2) {
            width: 70%;
        }


        ul{
            margin: 0 auto;
            padding: 9px 0 0;
            margin: 0;
            text-align: center;
            max-width: 750px;
            font-size: 0.727em;

            @include media(small-medium-3) {
                padding: 0;
                margin: 0 auto;
                text-align: center;
                max-width: none;
            }

            @include media(extra-large) {
                text-align: left;
                margin: 0;
                float: right;
                display: block;
            }

        }

        li{
            position: relative;
            list-style: none;

            @include media(small-medium-3) {
                display: inline-block;
            }

            &:first-of-type {
                a {
                    @include media(small-medium-3) {
                        padding-top: 0;
                        padding-left: 0;
                    }
                }
            }
            &:last-of-type {
                a {
                    @include media(small-medium-3) {
                        padding-right: 0;
                    }
                }
            }
        }


        a{
            font-size: 1em;
            font-weight: 600;
            line-height: 2em;
            text-align: left;
            color: $charcoal-grey-four;
            text-transform: uppercase;
            text-decoration: none;

            display: block;
            padding: 10px 25px;

            @include media(small-medium-3) {
                padding: 25px 10px;
            }

            @include media(medium) {
                padding: 25px 10px 0 10px;
            }

            @include media(extra-large) {
                padding: 25px 33px 0 33px;
            }
        }

        a:hover{
            color: $royal;
            font-weight: 900;

            @include media(small-medium-3) {
                font-size: 1.25em;
                transition-timing-function: ease;
                transition: 0.3s;
                padding-top: 0px;
               line-height: 32px;
            }
        }

        li.active a{
            color: $royal;
            font-weight: 900;
        }

        .nav-toggle {
            @include media(small-medium-3){
                display: none;
            }
        }

        .nav-toggle-link {
            position: relative;
        }

        .nav-toggle-link::after {
            color: #c1c6cb;
            content: "";
            font-size: 1.5em;
            position: absolute;
            right: 10px;
            background: url('#{$url}/static/images/navigation-icons.png') no-repeat 0 15px transparent;
            width: 20px;
            height: 42px;
            line-height: 42px;

            @include media(small){
                right: 25px;
            }
        }

        .nav-toggle-link:hover::after {
            background-position: -43px 15px;
        }

        .nav-toggle-link-on::after {
           background-position: 0 -21px;
        }

       .nav-toggle-link-on:hover::after {
           background-position: -43px -21px;
        }

        .nav-off {
            display: none;

            @include media(small-medium-3){
                display: block;
            }
        }
    }
}

