.cta-box{
    border: solid 5px #ffffff;
    padding: 20px;
    margin: 10px 5px 5px 5px;
    width: calc(100% -10px);
    position: relative;
    text-decoration: none;
    
    -webkit-flex-direction: column; /* Safari */
    flex-direction: column;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    height: 100%;
    
    &:hover{
       filter: brightness(110%);
    }
    
    &:nth-child(1){
        
        p{
            order: 2;
        }
        
        img{
            order: 1;
        }
    }
    &:nth-child(2){
        
        p{
            order: 2;
        }
        
        img{
            order: 1;
        }
    }
    &:nth-child(3){
        p{
            order: 2;
        }
        
        img{
            order: 1;
        }
    }
    &:nth-child(4){
        p{
            order: 2;
        }
        
        img{
            order: 1;
        }
    }
    
    @include media(medium){
        &:nth-child(3){
            p{
                order: 1;
                margin-right: 15px;
                margin-left: 0;
            }
            
            img{
                order: 2;
            }
        }
        &:nth-child(4){
            p{
                order: 1;
                margin-right: 15px;
                margin-left: 0;
            }
            
            img{
                order: 2;
            }
        }
    }
    
    @include media(small-medium){
        -webkit-flex-direction: row; /* Safari */
        flex-direction: row;
        align-items: center;
        border: none;
        padding: 10px;
        margin: 5px;
    }
    
    @include media(medium){
        width: calc(50% - 10px);      
        float: left;      
    }
    
    @include media(large){
        padding: 0;
        margin: 0;
        outline: none !important;
        width: 50%;
    }
    
    img{
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
        display: block;
        
        @include media(small-medium){
            width: 50%;
        }
    }

    p{
        color: $white;
        font-size: 1.045em;
        font-weight: bold;
        text-align: center;
        display: block;
        margin: 20px 0 0 0;

        @include media(small-medium){
            font-size: 1.382em;
            width: 50%;
            border: solid 5px #ffffff;
            padding: 20px;
            margin: 0 0 0 15px;
        }   
        
        @include media(medium){
            font-size: 1em;
            padding: 15px;
        }
        
        @include media(large){
            font-size: 1.382em;
            padding: 20px;
            margin: 0 20px !important;
            display: block;
            width: calc(50% - 40px);
        }

    }
    
    &.colour-ocean-blue{
        background-color: $ocean-blue;
        text-shadow: 0 0 20px darken($ocean-blue, 20%);
        outline: 5px solid $ocean-blue;
    }
    
    &.colour-squash{
        background-color: $squash;
        text-shadow: 0 0 20px darken($squash, 20%);
        outline: 5px solid $squash;
    }
    
    &.colour-turtle-green{
        background-color: $turtle-green;
        text-shadow: 0 0 20px darken($turtle-green, 20%);
        outline: 5px solid $turtle-green;
    }
    
    &.colour-medium-purple{
        background-color: $medium-purple;
        text-shadow: 0 0 20px darken($medium-purple, 20%);
        outline: 5px solid $medium-purple;
    }
}

.tiled-page-cta{
    background-color: $white-three;
}

.tiled-page{
    .cta-box:nth-child(7){
        @include media(medium){
            margin-left: 25%;
            margin-right: 25%;
        }
    }
}