// Main body

body{
    width: 94%;
    margin: 3%;
    max-width: 1395px;

    @include media(medium){
        width: 96%;
        margin: 0 2%;
    }

    @include media(extra-large){
        margin: 0 auto;
    }
}